import React from "react"

import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"

export default () => {

	return (
		<>
			<h2>Delete Account</h2>
			<Button variant="danger">Delete Account</Button>
		</>
	);
}