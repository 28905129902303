import React from "react"

import Form from "react-bootstrap/Form"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"

import { getSession } from "../../../services/auth"

export default () => {
	const session = getSession();

	const submit = (event) => {
		event.preventDefault();
		console.log("submit");
	}

	return (
		<>
			<h2>Update Account</h2>
			<Form>
				<Form.Group>
					<Form.Label>Email Address (not possible to change this yet)</Form.Label>
					<Form.Control type="text" defaultValue={session.user.email} disabled></Form.Control>
				</Form.Group>
				<Form.Group>
					<Form.Label>First Name</Form.Label>
					<Form.Control type="text" defaultValue={session.user.name}></Form.Control>
				</Form.Group>
				<Form.Row>
					<Form.Group as={Col}>
						<Form.Label>New Password (optional)</Form.Label>
						<Form.Control type="password" placeholder="new password"></Form.Control>
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Label>Confirm New Password</Form.Label>
						<Form.Control type="password" placeholder="confirm new password"></Form.Control>
					</Form.Group>
				</Form.Row>
				<Form.Group>
					<Form.Label>Current Password (required for all changes)</Form.Label>
					<Form.Control type="password" placeholder="current password"></Form.Control>
				</Form.Group>
				<Button variant="primary" type="submit" onClick={submit}>
					Submit Changes
				</Button>
			</Form>
		</>
	);
}