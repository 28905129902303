import React from "react"

import UpdateAccount from "./update"
import DeleteAccount from "./delete"

export default () => {

	return (
		<>
			<UpdateAccount />
			<br></br>
			<br></br>
			<DeleteAccount />
		</>
	);
}